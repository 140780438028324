<template>
  <section class="exam-result">
    <div class="color-section">
      <mobile-screen-header
        @onFilterOptionClick="filterMenu"
        @onFilterClick="sheet = true"
        :filterItems="student_result ? items : []"
        title="Exam Results"
      >
      </mobile-screen-header>

      <div class="body-content">
        
        <div class="container">
          <mobile-screen-box 
          @click="sheet=true"
          :title="selected_students.name ? selected_students.name : 'Student & Examination'" 
          :info="selected_students.exam_name ? selected_students.exam_name : 'Click to select'"></mobile-screen-box>
        </div>

        <div class="container">
          <div class="subject-table" v-if="student_result">
            <div class="subject-title">
              <h1>Subject Wise Grade</h1>
            </div>

            <v-data-table
              v-if="student_result"
              :headers="headers"
              :items="student_result.marksheets"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-left">
                    <div class="subject">
                      <h2>{{ item.subject.name }}</h2>
                      <p v-if="item.subject.sub_type == 'comp'">Compulsory</p>
                      <p v-else>Optional</p>
                    </div>
                  </td>
                  <td>
                    <h3>{{ item.sub_fullmark }}</h3>
                  </td>
                  <td>
                    <h3>{{ item.oth_grade }}</h3>
                  </td>
                  <td>
                    <h3>{{ item.opr_grade }}</h3>
                  </td>
                  <td>
                    <h3>
                      {{ item.grade_point.toFixed(1) }} ({{ item.oth_grade }})
                    </h3>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <not-found
            v-else-if="!student_result && !pendingDues"
            :width="400"
            :title="'Gradesheet not available'"
            :text="
              'Please select the student and exam to fetch the grade sheet'
            "
          ></not-found>
          <not-found
            v-else-if="!student_result && pendingDues"
            :width="400"
            :title="'Result Blocked'"
            :text="duesMessage"
          ></not-found>
        </div>
      </div>
    </div>

    <!-- ====================== Filter Drawer Started ===================== -->
    <v-navigation-drawer
      v-model="sheet"
      absolute
      bottom
      temporary
      right
      width="700"
    >
      <div class="filter-container">
        <div class="white--text filter-header">
          <h1>Filter Exam</h1>
          <p>Select Student / Exam to fetch marksheet.</p>
        </div>

        <div class="filter-body">
          <div class="filter-content-holder">
            <guardian-children-list
              :result="true"
              v-on:setArrayKey="setArrayKey"
              :reset_data="reset_data"
            ></guardian-children-list>
          </div>

          <v-divider></v-divider>
          <br />
          <div class="filter-content-holder pa-5">
            <v-select
              style="background-color: #fff"
              :items="exams"
              class="pa-0 mb-0"
              label="Exam"
              v-model="exam_id"
              outlined
              @change="setExamName()"
            />
          </div>
        </div>

        <v-card-actions class="filter-footer-action">
          <v-layout row wrap style="margin-bottom:20px;">
            <v-flex xs3>
              <v-btn
                elevation="0"
                color="default"
                large
                block
                @click="sheet = !sheet,resetData()"
              >
                Close
              </v-btn>
            </v-flex>
            <v-flex xs9 pl-2>
              <v-btn
                elevation="0"
                color="primary"
                block
                large
                @click="getExamResult"
                :disabled="disable_filter"
              >
                Filter
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </div>
    </v-navigation-drawer>

    <!-- =====================  Filter Drawer Ended =====================  -->
  </section>
</template>

<script>
import { redirectDownloadUrl } from "@/library/helpers";
export default {
  data: () => ({
    reset_data:false,
    sheet: false,
    exam_id: "",
    headers: [
      {
        text: "Subjects",
        align: "start",
        sortable: false,
        value: "",
      },
      { text: "FM", value: "", sortable: false },
      { text: "TH ", value: "", sortable: false },
      { text: "PR ", value: "", sortable: false },
      { text: "Grade", value: "", sortable: false },
    ],

    exams: [],
    enroll_id: "",
    enroll_code: "",
    gradeId: "",
    student_result: null,
    childrens: [],
    selected_students: {
      name: "",
      exam_name: "",
    },
    pendingDues: false,
    duesMessage: "",
    items: [{ title: "Filter Student" }, { title: "Download Result" }],
    disable_filter:true
  }),

  mounted() {
    this.getExams();
  },
  
  methods: {
    setExamName() {
      let that = this;
      let exam_name = this.exams.filter(function(exam) {
        if (that.exam_id == exam.value) return exam;
      });
      this.selected_students.exam_name = exam_name[0].text;
      if(this.enroll_id != ''){
        this.disable_filter = false
      }
    },
    filterMenu({ item, i }) {
      if (i == 0) {
        this.sheet = true;
      } else {
        let domain_name = this.$encryption.encrypt(
          this.$auth.company("domain")
        );

        redirectDownloadUrl({
          uri: "/print/exam/marksheet/preview/foruser",
          queryString: `school=${domain_name}&examId=${this.exam_id}&enrollId=${
            this.enroll_id
          }&gradeId=${this.gradeId}&current_batch=${
            this.$auth.getCurrentBatch().id
          }`,
          addCompanyBatchInfo: false,
        });
      }
    },
    getExams() {
      this.$rest.get("/api/exam").then(({ data }) => {
        this.exams = data.data.map((item) => {
          return { value: item.id, text: item.name };
        });
      });
    },
    getExamResult() {
      this.pendingDues = false;
      this.duesMessage = "";
      this.student_result = null;
      this.$rest
        .get(
          "/api/report/exam/marksheet?examId=" +
            this.exam_id +
            "&enrollId=" +
            this.enroll_id +
            "&enrollCode=" +
            this.enroll_code +
            "&gradeId=" +
            this.gradeId
        )
        .then(({ data }) => {
          this.student_result = data;
          this.sheet = false;
        })
        .catch((err) => {
          this.sheet = false;
          if (err.response &&( err.response.status === 406 || err.response.status === 422)) {
            this.pendingDues = true;
            this.duesMessage = err.response.data.message;
          }
        })
        // .finally(() => {});
    },
    // fetchGuardianChildern() {
    //   this.$rest
    //     .get("/api/guardian-children")
    //     .then(({ data }) => {
    //       this.childrens = data.data;
    //     })
    //     .then((e) => {});
    // },
    setArrayKey(value) {
      let filter_student_result = value;
      this.selected_students.name = filter_student_result.full_name;
      this.enroll_id = filter_student_result.academic.enroll_id;
      this.enroll_code = filter_student_result.academic.enroll_code;
      this.gradeId = filter_student_result.academic.grade_id;
      if(this.exam_id){
        this.disable_filter = false
      }
    },
    resetData(){
      this.reset_data = true
      this.selected_students.name = ''
      this.selected_students.exam_name = ''
      this.enroll_id = '';
      this.enroll_code = '';
      this.gradeId = '';
      this.exam_id = '';
      this.disable_filter = true
    }
  },
  computed: {
    getAttendancePercent() {
      if (!this.student_result.attendance) return "N/A";
      return (
        (this.student_result.attendance.total_present /
          this.student_result.attendance.total_attendance) *
        100
      ).toFixed(2);
    },
  },
};
</script>
